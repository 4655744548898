import axios from 'axios';

// 用户报名
export const isSigin = apiParams => {
  return axios({
    method: 'post',
    url: 'userSign/sign',
    data: apiParams
  })
}

// 获取用户报名信息、
export const getSign = apiParams => {
  return axios({
    method: 'GET',
    url: 'userSign/querySign',
    params: apiParams
  })
}

//教练申请
export const coachSign = apiParams => {
  return axios({
    method: 'post',
    url: 'user/coach',
    data: apiParams
  })
}

// 教练信息审核
export const coachDetail = apiParams => {
  return axios({
    method: 'get',
    url: 'user/getCoach',
    params: apiParams
  })
}